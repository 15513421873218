import Swiper from 'swiper/js/swiper';
import 'es6-promise/auto'
import Vue from 'vue';
import moment from "moment";
moment.locale('ja');
import axios from 'axios';
const youtube_path = 'https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=UUhp75SfuxC84Enpi0-jVo8g&maxResults=8&order=date&key=AIzaSyAAiSTj16VSj71aG_Dw64K6MDpdOjY5kN0';

// コメント：movieの直書き対応で一旦非表示
// if (document.getElementById("index") != null) {
//   var swiperConfig = {
//     speed: 500,
//     loop: true,
//     loopedSlides: 8,
//     centeredSlides: true,
//     preloadImages: true,
//     updateOnImagesReady: true,
//     autoplay: {
//       delay: 5000,
//     },
//     pagination: {
//       el: '.swiper-pagination',
//       clickable: true,
//     },
//     navigation: {
//       nextEl: '.moviesController-next',
//       prevEl: '.moviesController-prev',
//     },
//   }
//   new Vue({
//     el: '#movies',
//     filters: {
//       moment(value, format) {
//         return moment(value).format(format);
//       }
//     },
//     data: {
//       movies: null,
//       loading: true
//     },
//     beforeCreate() {
//       axios
//         .get(youtube_path)
//         .then(response => {
//           this.movies = response.data.items
//         })
//         .finally(() => {
//           this.loading = false
//         })
//     },
//     updated() {
//       var swiper = new Swiper('.swiper-container', swiperConfig)
//     },
//   })
// }

// コメント：topのswiperを定義
function swiperIndex() {
// コメント：カスタマイズ内容を記載
var swiperConfig = {
  speed: 500,
  loop: true,
  loopedSlides: 8,
  centeredSlides: true,
  preloadImages: true,
  updateOnImagesReady: true,
  autoplay: {
    delay: 5000,
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  navigation: {
    nextEl: '.moviesController-next',
    prevEl: '.moviesController-prev',
  },
}
// コメント：カスタマイズ内容を呼び出し
var swiper = new Swiper('.swiper-container', swiperConfig)

};
// コメント：関数を実行
swiperIndex();
